<template>
  <v-layout row wrap>
    <v-flex xs12 max-width-1400 full-width ma-auto px-2>
      <v-container grid-list-xl fluid ma-0 pa-0>
        <v-layout row wrap>
          <v-flex xs12>
            <h3
              class="primary--text graphik-medium my-3 text-xs-center"
              :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_insurances_title') }}</h3>

            <p
              class="dark-grey--text graphik-light text-xs-center max-width-900 mb-4 mx-auto"
              :class="{'font-17': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_insurances_description_1') }}</p>
            <p
              class="dark-grey--text graphik-light text-xs-center max-width-900 mb-4 mx-auto"
              :class="{'font-17': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_insurances_description_2') }}</p>
            <p
              class="dark-grey--text graphik-light text-xs-center max-width-900 mb-4 mx-auto"
              :class="{'font-17': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_insurances_description_3') }}</p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentAboutInsurances'
}
</script>

<style scoped>
</style>
